// Things related to the shared shene page
const SharedScene = {
	// group ID of the sharing group, the group whose id is in the url
	// This is not the same as the group the user might be in
	sharingGroupID : null, 
	makeNewSceneSharingCodeAndUpdateInUI : async (grid) => {
		CP.notify('Generating new scene sharing code', true);
		let result = await Cloud.getNewInviteCode(grid, 1); // Usage code here is 1, because it's a share group
		if (result.success) {
			CP.notify('New scene sharing code: ' + result.inviteCode, false);
			getel('inviteCodeText').textContent = result.inviteCode;
		} else CP.showErrorMessage("Failed to get scene sharing code!");
	},
	sceneSharingButtonClicked : async () => {
		if (SharedScene.sharingGroupID) {
			SharedScene.makeNewSceneSharingCodeAndUpdateInUI(SharedScene.sharingGroupID);
		} else
			// This should probably not happen
			CP.showErrorMessage("Can't make scene sharing code because group ID is missing!");
	},
	getSceneAndUpdatePage : async (grid) => {
		// Assume current version since scenes are not available in version 0
		// TBD : Might want to review later how we get the version here
		let indexRes = await Cloud.getIndexFromCloud({ groupID: grid, version : Constants.CurrentVersion});
		let sceneEntry = null;
		let sceneEntryKey = null;
		let indexFile = null;
		if (indexRes.success) {
			indexFile = indexRes.indexFile;
			// Find the first entry that is a scene entry
			for(const key in indexFile.Entries) {
				let entry = indexFile.Entries[key];
				if (entry.File?.ArkioScene != null) {
					sceneEntry = entry;
					sceneEntryKey = key;
					getel('sceneName').innerHTML = sceneEntry.File.Filename;
					break;
				}
			}
		}

		let staticMessageArea = getel('staticMessageArea');
		let notificationArea = getel('notificationArea');
		let sharedSceneDiv = getel('sharedSceneDiv');

		if (sceneEntry) {
			makeElementVisible(staticMessageArea);
			makeElementVisible(notificationArea);
			makeElementVisible(sharedSceneDiv);
			let textBubbleArea = getel('staticMessageArea');
			let newToArkioMessage = getel('newToArkioMessage');
			makeElementVisible(getel('sceenSharingCodeButton'));
			SharedScene.makeNewSceneSharingCodeAndUpdateInUI(grid);
			if (isLinked()) {
				makeElementHidden(newToArkioMessage);
				let textBubble1 = new TextBubble(textBubbleArea, "", TextBubbleStyle.Simple, null, false);
				textBubble1.setText(getel('linkedMessage1').innerHTML, true);
				// If connected to a group then enable saving the scene to that group.
				let groupInfo = retrieveEncryptionKey();
				let sceneSaveButton = getel('sceneSaveButton');
				sceneSaveButton.onclick = async () => {
					CP.notify('Saving', true);
					let indexRes = await Cloud.addToIndex(sceneEntryKey, sceneEntry, groupInfo);
					if (indexRes.success) {
						CP.notify('Scene saved to cloud group', false);
					} else CP.showErrorMessage("Failed to copy scene to group!");
				}
				makeElementVisible(sceneSaveButton);
			} else {
				let textBubble2 = new TextBubble(textBubbleArea, "", TextBubbleStyle.Simple, null, false);
				textBubble2.setText(getel('unlinkedMessage1').innerHTML, true);
				makeElementVisible(newToArkioMessage);
			}
		} else {
			makeElementHidden(staticMessageArea);
			makeElementHidden(notificationArea);
			makeElementHidden(sharedSceneDiv);
			let textBubble1 = new TextBubble(getel('errorArea'), "It looks like the file you're trying to access is no longer available. Please ask the sender to share a new link with you.", TextBubbleStyle.Error, null, false);
		}
	},
	DOMContentLoaded : () => {
		CP.initMsgManager(getel('errorArea'), getel('staticMessageArea'), getel('notificationArea'));

		document.getElementById("copyButtonSpan").addEventListener("click", async () => {
			CP.notify("Copying scene sharing code", true);
			let inviteCode = getel('inviteCodeText').innerHTML;
			await copyToClipboard(inviteCode);
			CP.notify("Scene sharing code " + inviteCode + " copied to clipboard", false);
		});
	},
	bodyOnLoad: () => {
		makeElementVisible(getel('sharedSceneListItem'));
		setVisibility(getel('navpanel'), isLinked());
		initPage({ publicPage : true });
		let base64 = getEncryptionKeyFromUrl('sharegroupid');
		SharedScene.sharingGroupID = convertGroupIDFromBase64(base64);
		if (SharedScene.sharingGroupID) {
			SharedScene.getSceneAndUpdatePage(SharedScene.sharingGroupID); 
		} else goToGroupPage();
	}
};
